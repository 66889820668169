import helper from '@/api/helper.js';
import chartOptions from '../chart/chart_options';
import common from '../component/common.js';

const getSantralMinuteDataChart = (_rawData, measureList, text, chartTypeModel, reportTypeModel,
  santralModel) => new Promise((resolve) => {
  if (_rawData.success == 'false' || Object.keys(_rawData.data).length === 0) {
    const result = {
      options: {},
      desserts: [],
    };
    const desserts = [];
    const options = chartOptions.line_options_multies('', [], [], [], [], []);

    result.options = options;
    result.desserts = desserts;

    resolve(result);
    return;
  }

  let max_val = 0;

  const results = {};
  _rawData.data.forEach((item) => {
    const Month = item.Month < 10 ? `0${item.Month}` : item.Month;
    const Day = item.Day < 10 ? `0${item.Day}` : `${item.Day}`;

    let time = '';
    if (reportTypeModel == 'Minute') {
      const hour = item.Hour < 10 ? `0${item.Hour}` : `${item.Hour}`;
      const MinuteGrup = item.MinuteGrup < 10 ? `0${item.MinuteGrup}` : `${item.MinuteGrup}`;
      time = `${hour}:${MinuteGrup}`;
    }

    if (reportTypeModel == 'Hourly') {
      time = item.Hour < 10 ? `0${item.Hour}` : `${item.Hour}`;
    }
    if (reportTypeModel == 'Daily') {
      time = `${item.Year}-${Month}-${Day}`;
    }
    if (reportTypeModel == 'Monthly') {
      time = `${item.Year}-${Month}`;
    }
    if (reportTypeModel == 'Yearly') {
      time = item.Year;
    }

    if (!results.hasOwnProperty(time)) {
      results[time] = {};
    }

    measureList.forEach((measure) => {
      if (!results[time].hasOwnProperty(measure.value)) {
        results[time][measure.value] = 0;
      }

      results[time][measure.value] += parseFloat(item[measure.value]);

      if (parseInt(results[time][measure.value]) > max_val) {
        max_val = parseInt(results[time][measure.value]);
      }
    });
  });

  const colors = common.colors();

  const series = [];
  const measures = [];
  const desserts = [];
  const result = {};
  const yAxis = [];
  const dateList = Object.keys(results).sort();
  let offset = 0;
  let colorKey = 0;

  measureList.forEach((measure) => {
    measures.push(`${measure.text}(${measure.unit})`);
    const tempData = [];

    const total = 1;

    dateList.forEach((time) => {
      const obj = {};

      if (colorKey === 0) {
        obj.date = time;
        const m_total = 0;
        measureList.forEach((m) => {
          const tempVal = common.setData(results[time], m.value, m_total);

          if (m.value === 'PerformanceRatio' && tempVal > 0) {
            obj[m.value] = helper.setFixed(tempVal / santralModel.length);
          } else {
            obj[m.value] = tempVal;
          }
        });

        desserts.push(obj);
      }

      const chartTempVal = common.setData(results[time], measure.value, total);
      let v = 0;
      if (measure.value === 'PerformanceRatio' && chartTempVal > 0) {
        v = helper.setFixed(chartTempVal / santralModel.length);
      } else {
        v = chartTempVal;
      }
      tempData.push(v);
    });

    let chartType = chartTypeModel;
    if (chartTypeModel == 'line2') {
      chartType = 'line';
    }

    const obj = {
      name: `${measure.text}(${measure.unit})`,
      data: tempData,
      type: chartType,
    };

    const position = 'left';

    if (colorKey > 1) {
      offset += 110;
    }
    if (chartTypeModel == 'line2') {
      obj.areaStyle = {};
    }

    const max = parseInt(max_val) * 130 / 100;

    if (colorKey < 1) {
      yAxis.push({
        type: 'value',
        name: measure.text,
        min: 0,
        max,
        offset,
        position,
        show: true,
        axisLabel: {
          formatter: '{value} ',
          show: true,
        },
      });
    }

    series.push(obj);
    colorKey += 1;
  });

  const options = chartOptions.line_options_multies(text, measures, dateList, series,
    colors, yAxis);

  result.options = options;
  result.desserts = desserts;

  resolve(result);
});

export default {
  getSantralMinuteDataChart,
};
