<template>
  <div>
    <v-col cols="12">
      <v-card>
        <v-col cols="12" class="pl-0 pr-0 pb-1">
          <v-row>
            <v-spacer />
            <v-col cols="2" class="pr-1">
              <v-select
                v-model="reportTypeModel"
                :items="reportType"
                label="Yıl"
              />
            </v-col>

            <v-col
              v-if="['Monthly', 'Weekly'].indexOf(reportTypeModel) > -1"
              cols="3"
              sm="1"
              md="1"
              class="pr-3"
            >
              <v-select v-model="yearModel" :items="yearList" label="Yıl" />
            </v-col>

            <v-col
              v-if="reportTypeModel === 'Daily'"
              cols="3"
              sm="1"
              md="1"
              class="pr-3"
            >
              <v-select v-model="monthModel" :items="monthList" label="Ay" />
            </v-col>

            <v-col
              v-if="reportTypeModel === 'Weekly'"
              cols="3"
              sm="2"
              md="2"
              class="pr-3"
            >
              <v-select v-model="WeeklyModel" :items="weekList" label="Hafta" />
            </v-col>

            <v-col
              v-if="reportTypeModel == 'Hourly' || reportTypeModel == 'Minute'"
              cols="3"
              sm="2"
              md="2"
              class="pr-3"
            >
              <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                :return-value.sync="startDate"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="startDate"
                    label="Tarih"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  />
                </template>
                <v-date-picker v-model="startDate" no-title scrollable>
                  <v-spacer />
                  <v-btn text color="primary" @click="menu = false">
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.menu.save(startDate)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>

            <v-col cols="2" class="pr-3 mt-4">
              <v-select
                v-model="measuresModel"
                :items="measures"
                label="Ölçü"
                dense
                multiple
              >
                <template v-slot:selection="{ item, index }">
                  <span
                    v-if="index === 0"
                    style="max-width: 120px"
                    class="d-inline-block text-truncate"
                    >{{ item.text }}</span
                  >
                  <span v-if="index === 1" class="grey--text text-caption"
                    >(+{{ measuresModel.length - 1 }})</span
                  >
                </template>
              </v-select>
            </v-col>

            <v-col cols="1" sm="1" md="1" class="pr-3">
              <v-select
                v-model="chartTypeModel"
                :items="chartType"
                label="Grafik"
              />
            </v-col>

            <v-col cols="3" sm="1" md="1" class="pr-3 pt-3">
              <v-btn color="primary" @click="getSantralDataChart">
                Filtrele
              </v-btn>
            </v-col>

            <v-col cols="3" sm="1" md="1" class="pr-3 pt-3">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    fab
                    dark
                    small
                    color="cyan"
                    v-on="on"
                    @click="exportDocument"
                  >
                    <v-icon>mdi-download</v-icon>
                  </v-btn>
                </template>
                <span>Export to Excel</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12">
          <div id="general-power-daily-stats" :style="style" />
        </v-col>
      </v-card>
    </v-col>

    <v-col cols="12" class="mt-1 pt-0">
      <v-card>
        <v-data-table
          :id="santralDataTable"
          dense
          :items-per-page="itemsPerPage"
          :headers="headers"
          :items="desserts"
          class="elevation-1"
        />
      </v-card>
    </v-col>

    <ExportDocument :export-document-open="exportDocumentOpen" />
  </div>
</template>
<script>
import * as echarts from "echarts";
import * as SantralStats from "@/api/Analysis/SantralStats";
import moment from "moment";
import ExportDocument from "@/views/components/Shared/ExportDocument.vue";

import {
  getSantralMinute,
  getSantralHourly,
  getSantralDaily,
  getSantralMonthly,
  getSantralYearly,
} from "@/api/Request/santral";

export default {
  name: "GeneralStatsHour",
  components: {
    ExportDocument,
  },
  data: () => ({
    chartData: {},
    startDate: moment().format("YYYY-MM-DD"),
    finishDate: moment().format("YYYY-MM-DD"),
    menu: false,
    modal: false,
    menu2: false,
    measuresModel: [
      /*   'IstantaneousPower',
      'PerformanceRatio',
      'TheoreticalPower', */
      "DailyEnergy",
    ],
    itemsPerPage: 500,
    yearModel: parseInt(moment().format("YYYY")),
    monthModel: parseInt(moment().format("MM")),
    measures: [],
    chartType: ["line", "bar", "line2"],
    chartTypeModel: "bar",
    componentKey: 1,
    headers: [],
    desserts: [],
    reportTypeModel: "Daily",
    reportType: [],
    weekListModel: 10,
    weekList: [],
    exportDocumentOpen: {
      open: false,
      tableID: null,
    },
    santralDataTable: "",
    chartText: "",
    windowsWidth: "",
    newData: "true",
    style: "",
    santralModel: [],
  }),
  computed: {
    yearList() {
      return this.$store.getters.clientYearList;
    },
    monthList() {
      return this.$store.getters.month;
    },
  },
  watch: {
    reportTypeModel() {
      const { reportTypeModel } = this;

      this.measures = [];
      if (["Minute", "Hourly"].indexOf(reportTypeModel) > -1) {
        this.measures = [
          { text: "Anlık Güç", value: "IstantaneousPower", unit: "kW" },
          { text: "Performans oranı", value: "PerformanceRatio", unit: "%" },
          { text: "Teorik Güç", value: "TheoreticalPower", unit: "kW" },
          /*    { text: 'Equivalent Trees', value: 'EquivalentTrees', unit: '' },
          { text: 'CO2 Avoided', value: 'CO2Avoided', unit: '' },
          { text: 'Barrels of Oil', value: 'BarrelsofOil', unit: '' }, */
        ];

        this.measuresModel = ["IstantaneousPower"];
      } else {
        this.measures = [
          { text: "Günlük Enerji", value: "DailyEnergy", unit: "kW" },
          { text: "Performans oranı", value: "PerformanceRatio", unit: "%" },
          { text: "Teorik Güç", value: "TheoreticalPower", unit: "kW" },
          /*  { text: 'Equivalent Trees', value: 'EquivalentTrees', unit: '' },
          { text: 'CO2 Avoided', value: 'CO2Avoided', unit: '' },
          { text: 'Barrels of Oil', value: 'BarrelsofOil', unit: '' }, */
        ];
        this.measuresModel = ["DailyEnergy"];
      }
      this.getSantralDataChart();
    },
  },
  created() {
    this.windowsWidth = `${document.documentElement.clientWidth - 150}px`;
    this.style = `height: 300px; width:${
      document.documentElement.clientWidth - 150
    }px`;
  },

  mounted() {
    this.measures = [
      { text: "Toplam Enerji", value: "DailyEnergy", unit: "kW" },
      { text: "Performans oranı", value: "PerformanceRatio", unit: "%" },
      { text: "Teorik Güç", value: "TheoreticalPower", unit: "kW" },

      // { text: "Anlık Güç", value: "IstantaneousPower" },
      /*  { text: 'Equivalent Trees', value: 'EquivalentTrees', unit: '' },
      { text: 'CO2 Avoided', value: 'CO2Avoided', unit: '' },
      { text: 'Barrels of Oil', value: 'BarrelsofOil', unit: '' }, */
    ];

    this.reportType = [
      { text: "Dakika", value: "Minute" },
      { text: "Saatlik", value: "Hourly" },
      { text: "Günlük", value: "Daily" },
      { text: "Aylık", value: "Monthly" },
      { text: "Yıllık", value: "Yearly" },
    ];

    const self = this;
    const myVar2 = setInterval(myTimer2, 1000);

    function myTimer2() {
      const { santralDataList } = self.$store.getters;
      if (
        santralDataList !== undefined &&
        Object.keys(santralDataList).length > 0
      ) {
        self.santralModel = santralDataList;
        clearInterval(myVar2);
        self.getSantralDataChart();
      }
    }
  },
  methods: {
    exportDocument() {
      this.exportDocumentOpen.open = true;
      this.exportDocumentOpen.tableID = this.santralDataTable;
    },

    forceRerender() {
      this.renderComponent = false;
      this.$nextTick().then(() => {
        this.renderComponent = true;
      });
    },
    /// / genel istatistik start
    getSantralDataChart() {
      this.finishDate = moment(this.startDate)
        .add(1, "day")
        .format("YYYY-MM-DD");

      const { measuresModel, measures } = this;

      this.chartText = "";

      const measureList = [];
      const headers = [{ text: "Tarih", value: "date" }];
      measures.forEach((measure) => {
        if (measuresModel.indexOf(measure.value) > -1) {
          measureList.push(measure);
          headers.push(measure);
        }
      });

      this.headers = headers;
      const formdata = this.selectedParams();

      this.chartData = [];
      const self = this;
      let temp;

      let tempData;
      if (this.reportTypeModel === "Minute") {
        temp = getSantralMinute(formdata);
      }

      if (this.reportTypeModel === "Hourly") {
        temp = getSantralHourly(formdata);
      }
      if (this.reportTypeModel === "Daily") {
        temp = getSantralDaily(formdata);
      }
      if (this.reportTypeModel === "Monthly") {
        temp = getSantralMonthly(formdata);
      }
      if (this.reportTypeModel === "Yearly") {
        temp = getSantralYearly(formdata);
      }

      temp.then((_rawData) => {
        tempData = SantralStats.default.getSantralMinuteDataChart(
          _rawData.data,
          measureList,
          self.chartText,
          self.chartTypeModel,
          self.reportTypeModel,
          self.santralModel
        );

        this.setOpt(tempData);
      });
    },

    selectedParams() {
      const { reportTypeModel } = this;

      const params = {
        condiniton: {
          santral: this.santralModel,
          measures: this.measuresModel,
        },
      };

      if (reportTypeModel === "Yearly") {
        this.santralDataTable = `${this.yearModel}-santralDataTable`;
        params.condiniton.date = this.yearList;
      }

      if (reportTypeModel === "Monthly") {
        const monthL = [];
        for (let i = 1; i < 13; i += 1) {
          monthL.push(i);
        }
        this.santralDataTable = `${this.yearModel}-santralDataTable`;
        params.condiniton.date = [
          {
            year: this.yearModel,
            month: monthL,
          },
        ];
      }

      if (reportTypeModel === "Daily") {
        this.santralDataTable = `${this.yearModel} ${this.monthModel}-santralDataTable`;
        params.condiniton.year = this.yearModel;
        params.condiniton.month = this.monthModel;
      }

      if (reportTypeModel === "Hourly" || reportTypeModel === "Minute") {
        this.santralDataTable = `${this.startDate} - ${this.finishDate}-santralDataTable`;
        params.condiniton.startDate = this.startDate;
        params.condiniton.finishDate = this.finishDate;
      }

      return params;
    },
    setOpt(tempData) {
      const chartDom = document.getElementById("general-power-daily-stats");
      const myChart = echarts.init(chartDom);
      tempData.then((res) => {
        // this.chartData = res.options;
        myChart.setOption(res.options, true);
        this.desserts = res.desserts;
        this.componentKey += 1;
        this.forceRerender();
      });
    },
  },
};
</script>

<style scoped>
.theme--dark
  .fullgridtable
  .theme--light.v-data-table
  tbody
  tr:not(:last-child) {
  border-bottom: none;
}

.fullgridtable .v-data-table th {
  border: thin solid #0000001f;
}

.fullgridtable .v-data-table td {
  border: thin solid #0000001f;
}

.fullgridtable.theme--dark .v-data-table th,
.fullgridtable.theme--dark .v-data-table td {
  border: thin solid hsla(0, 0%, 100%, 0.12);
}

.fullgridtable .theme--light.v-data-table tbody tr:not(:last-child) {
  border-bottom: none;
}

.fullgridtable.fullorder .v-data-table th,
.fullgridtable.fullorder .v-data-table td {
  border-bottom: 0 !important;
}

.fullgridtable.fullorder .v-data-table td:not(:last-child),
.fullgridtable.fullorder .v-data-table th:not(:last-child) {
  border-right: 0 !important;
}
</style>
